import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, CssBaseline, Toolbar, Grid } from '@mui/material';
// project imports
import Header from './Header';

// assets
import SubFooter from 'views/dashboard/Default/SubFooter';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    ...theme.typography.mainContent
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const theme = useTheme();

    return (
        <>
            <CssBaseline />
            <AppBar
                position="absolute"
                sx={{
                    top: 0,
                    height: 120,
                    zIndex: 999,
                    boxShadow: 0,
                    bottom: 'auto',
                    backgroundColor: 'transparent'
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>
            <Main theme={theme}>
                <Outlet />
            </Main>
            <Grid item>
                <SubFooter />
            </Grid>
        </>
    );
};

export default MainLayout;
