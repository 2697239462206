// theme.js
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { lightColorUI, mainColorUI } from './constants';

// Light Theme
export const lightTheme = createTheme({
    // Define your light theme properties here
    typography: {
        fontFamily: 'Nunito'
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#1976D2'
        },
        secondary: {
            main: '#388E3C'
        },
        text: {
            primary: grey[100],
            secondary: grey[200],
            third: grey[300],
            invertedprimary: grey[800],
            invertedsecondary: grey[700],
            invertedthird: grey[700],
            invertedfourth: grey[500],
            brandUI: mainColorUI,
            walletbalancechange: lightColorUI,
            walletbuttons: lightColorUI,
            marketscardprimary: grey[700],
            marketscardsecondary: '#414141'
        },
        button: {
            send: mainColorUI
        },
        background: {
            default: '#050a1c',
            paper: '#050a1c'
        },
        card: {
            main: grey[50],
            secondary: grey[300],
            third: grey[400],
            ultra: grey[100],
            balancescard: grey[300],
            dashboardcard: grey[300]
        },
        btcharttimeframebutton: {
            main: grey[200]
        },
        settingsicon: {
            main: grey[600]
        },
        multichainicon: {
            main: grey[200],
            secondary: grey[700]
        },
        appbar: {
            main: grey[900]
        },
        govcardcirclebuttontext: {
            main: grey[700]
        }
    }
});
