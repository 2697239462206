// material-ui
import XIcon from '@mui/icons-material/X';
import { SiGitbook } from 'react-icons/si';
import { useTheme } from '@mui/material/styles';
import TelegramIcon from '@mui/icons-material/Telegram';
import baselogo from '../../../assets/base-wordmark.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const SubFooter = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container justifyContent="center" display="flex" mb={2}>
                <Grid container display="flex" lg={10} ml={2} alignItems="center" borderBottom={1.5} borderColor="#121628" pb={2}>
                    <Button
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Articles
                    </Button>
                    <Button
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Support
                    </Button>
                    <IconButton sx={{ background: '#121728', width: 30, height: 30, ml: 4 }}>
                        <XIcon sx={{ color: '#fff', fontSize: 18 }} />
                    </IconButton>
                    <IconButton sx={{ background: '#121728', width: 30, height: 30, ml: 1 }}>
                        <SiGitbook style={{ color: '#fff' }} />
                    </IconButton>
                    <IconButton sx={{ background: '#121728', width: 30, height: 30, mx: 1 }}>
                        <TelegramIcon sx={{ color: '#fff', fontSize: 18 }} />
                    </IconButton>
                    <IconButton sx={{ background: '#121728', width: 30, height: 30 }}>
                        <OpenInNewIcon sx={{ color: '#fff', fontSize: 18 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" display="flex" mb={7} ml={2}>
                <Grid container display="flex" lg={10} alignItems="center">
                    <Button
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Documentation
                    </Button>
                    <Button
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Security
                    </Button>
                    <Button
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Integrated Tokens
                    </Button>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" display="fle" mb={7}>
                <Grid container display="flex" justifyContent="space-between" lg={10} alignItems="center" mx={2}>
                    <Typography sx={{ color: grey[600], mt: 3 }}>2024 @ AlphaDAO Finance, v1.0.0beta+97pe4</Typography>
                    <Typography sx={{ color: grey[600], mt: 3 }}>
                        A community asset for <img src={baselogo} alt="logo" width={60} style={{ marginBottom: -2.5, marginLeft: 5 }} />
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default SubFooter;
