/* eslint-disable no-underscore-dangle */
// material-ui
import { Button, Grid, IconButton, Typography } from '@mui/material';
// project imports
import LogoSection from '../LogoSection';
// assets
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import TelegramIcon from '@mui/icons-material/Telegram';
import SegmentIcon from '@mui/icons-material/Segment';
import { useState } from 'react';
import { blue, green, grey } from '@mui/material/colors';
import MenuDrawer from 'views/utilities/MenuDrawer';
import HeaderMenu from './HeaderMenu';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const Header = () => {
    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
    const openMenuDrawer = () => {
        setIsMenuDrawerOpen(true);
    };

    const closeMenuDrawer = () => {
        setIsMenuDrawerOpen(false);
    };

    return (
        <>
            <Grid
                container
                sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: 'transparent',
                    justifyContent: 'space-between',
                    px: { lg: 10, md: 5, sm: 3, xs: 1 }
                }}
            >
                <Grid container display="flex" justifyContent="center" width="auto">
                    <LogoSection />
                </Grid>
                <Grid container width={500} sx={{ display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}>
                    <HeaderMenu />
                </Grid>
                <Grid container width="auto">
                    <Button
                        size="small"
                        sx={{
                            background: '#0052ff',
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            fontSize: 16,
                            color: grey[100],
                            px: 3,
                            py: 1
                        }}
                    >
                        Connect
                    </Button>
                </Grid>
            </Grid>
            <MenuDrawer isOpen={isMenuDrawerOpen} onClose={closeMenuDrawer} />
        </>
    );
};

export default Header;
